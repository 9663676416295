/* Inject dynatrace js based on dynatraceBrand in the url */

const queryString = window.location.search;
console.log(queryString);
const urlParams = new URLSearchParams(queryString);
let dynatraceBrand = '';
if (urlParams.has('brand'))
{
    dynatraceBrand = urlParams.get('brand');
    console.log('Dynatrace Brand: ', dynatraceBrand);
    var script = document.createElement('script'); 
    script.type = 'text/javascript'; 
    script.crossOrigin = 'anonymous'; 
    if (dynatraceBrand == 'cbu' || dynatraceBrand == 'r4b'
            || dynatraceBrand == 'fido'
            || dynatraceBrand == '1source'
            || dynatraceBrand == 'media'
            || dynatraceBrand == 'yahoo'
            || dynatraceBrand == 'chatr') { 
        script.src = 'https://js-cdn.dynatrace.com/jstag/17a2d2e42ea/bf97268ops/f6a10855bd143fc8_complete.js';                    
        $('head').append(script); 
    }         
    else { 
        console.warn('Dynatrace unknown Brand found in URL. ', dynatraceBrand);        
    }    
}
else {   
    console.warn('Dynatrace Brand not found');
}   
    